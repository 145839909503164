import { DEFAULT_PATHS } from 'config';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import React, { useEffect, useState } from 'react';
import { Card, Col, NavLink, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetAllCommonStudentInitialDataMutation } from 'slices/get/common';
import useService from 'views/default/useService';
const SubjectsView = ()=>{
    const location = useLocation();
    const currentPath = location.pathname;
    let history = useHistory()
    const { checkAccessTime, fetchAccessToken } = useService();
    const [getAllCommonStudentInitialData] = useGetAllCommonStudentInitialDataMutation();
    const [breadcrumbs, setBreadCrumbs] = useState([{ to: '', text: 'Home', id: 0 }]);

    const [subjectData, setSubjectData] = useState([]);
    const handleBreadCrumbs = (val) => {
        setBreadCrumbs((bc) => [...bc, val]);
      };



    useEffect(() => {
        const access_token = fetchAccessToken();
    
        getAllCommonStudentInitialData({ ep: '/common/studentInitialData', token: access_token }).then(
          (response) => {
            console.log(response);
            console.log('Custom data from after_request:', response);
    
            checkAccessTime(response, currentPath);
    
            // console.log('RESPONSE', response);
    
            setSubjectData(response.data.data.subjectData);
            // setLessonData(response.data.data.lessonData);
            // setPartData(response.data.data.partData);
          }
        );
    }, []);
    const shuffleArray = (array) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };
    
    const shuffledGradients = shuffleArray([
      'linear-gradient(135deg, #ff7e5f, #feb47b)',  // Warm Orange
      'linear-gradient(135deg, #42e695, #3bb2b8)',  // Green-Blue
      'linear-gradient(135deg, #f6d365, #fda085)',  // Peachy
      'linear-gradient(135deg, #ff9a9e, #fad0c4)',  // Pinkish
      'linear-gradient(135deg, #a1c4fd, #c2e9fb)',  // Light Blue
      'linear-gradient(135deg, #667eea, #764ba2)',  // Purple
    ]);
    return (
      <>
        <Col xl="12" className="mb-5">
          <h2 className="small-title">Subjects</h2>
          <Row className="g-2">
            {subjectData && subjectData.length > 0 && subjectData.map((v, i) => {
              const gradient = shuffledGradients[i % shuffledGradients.length]; // Cycle through gradients
    
              return (
                <Col
                  xs="6"
                  xl="2"
                  key={i}
                  className="mb-3"
                  onClick={() => {
                    history.replace(`${window.location.pathname}/homework?subjectID=${v.subject_id}`);
                  }}
                >
                  <Card
                    className="h-100 hover-scale-up"
                    style={{
                      background: gradient,
                      borderRadius: '15px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      color: 'white',
                    }}
                  >
                    {/* <Card.Body className="text-center">
                      <NavLink to="#">
                       
                        <p className="heading mt-3 text-body">{v.name}</p>
                        <div className="text-extra-small fw-medium text-muted text-white">
                          {v.subject_count} LESSONS
                        </div>
                      </NavLink>
                    </Card.Body> */}
                     <Card.Body
  className="d-flex flex-column justify-content-center align-items-center text-center"
  style={{
    height: '100%', // Ensure the content takes full height of the card
  }}
>
<h4
      className="heading fw-bold text-white"
      style={{
        fontSize: '20px',
        marginBottom: '1rem', // Add spacing between heading and button
        marginTop : '10px'
      }}
    >
      {v.name}
    </h4>
  {/* <NavLink to="#" style={{ textDecoration: 'none' }}>
    <h5
      className="heading fw-bold text-white"
      style={{
        fontSize: '17px',
        marginBottom: '1rem', // Add spacing between heading and button
      }}
    >
      {v.name}
    </h5>
    <div
      className="text-extra-small fw-medium fw-bold"
      style={{
        fontSize: '14px',
        textAlign: 'center',
      }}
    >
      <button
        className="btn btn-light fw-bold px-3 py-2 text-white"
        style={{
          fontSize: '14px',
          borderRadius: '30px',
          color: '#007bff',
          backgroundColor: '#4E499F',
        }}
      >
        {v.subject_count} LESSONS
      </button>
    </div>
  </NavLink> */}
</Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </>
    );
    
}

export default SubjectsView